import { memo as Memo } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Components
import Page from 'components/common/Page'
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Logo from 'components/common/Logo'
import Button from 'components/common/Button'

//* Style
import Custom404Style from 'styles/pages/Custom404Style'

//* Helpers
import { config } from 'helpers'

const Custom404 = Memo(({ winWidth, screenSizes }) => {
    return (
        <Page
            meta={{ title: 'Ohanyan | 404' }}
            className='page-404'
        >
            <Custom404Style>
                <Container className={'error-container'}>
                    <Logo className={'error-logo'} />
                    <div className={`content-wrap`}>
                        <Text
                            className={'h4 minion3-medium primary-color9 error-title'}
                            text={'404title'}
                        />
                        <Text
                            className={'p p2 notoSans-regular primary-color9 error-description'}
                            text={'404description'}
                        />
                        <Button
                            url={config.routes.home.path}
                            iconClassName={'primary-color1'}
                            className={'error-button'}
                            text={'goToHomepage'}
                        />
                    </div>
                </Container>
            </Custom404Style>
        </Page>
    )
})

export default withUIContext(Custom404, ['winWidth', 'screenSizes'])
