import styled from 'styled-components';

const Custom404Style = styled.div`
	--contentWrapPadding: var(--sp25x) 0;
	--descriptionMarginTop: var(--sp3x);
	--errorLogoTop: var(--sp2x);
	--errorButtonBottom: var(--sp8x);

	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	z-index: 100;
	background-color: var(--primaryColor1);

	.content-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-top: 1px solid var(--primaryColor9);
		border-bottom: 1px solid var(--primaryColor9);
		padding: var(--contentWrapPadding);
		text-align: center;

		.error-description {
			margin-top: var(--descriptionMarginTop);
		}
	}

	.error-logo {
		position: fixed;
		top: var(--errorLogoTop);
		left: 50%;
		transform: translateX(-50%);
		width: var(--logoWidth);

		.image-cont {
			padding-top: var(--logoHeight);
			background-color: transparent !important;
		}
	}

	.error-button {
		position: fixed;
		bottom: var(--errorButtonBottom);
		left: 50%;
		transform: translateX(-50%);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--contentWrapPadding: var(--sp19x) 0;
		--descriptionMarginTop: var(--sp2x);
		--errorLogoTop: var(--sp2-5x);
		--errorButtonBottom: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--contentWrapPadding: var(--sp17x) 0;
		--descriptionMarginTop: var(--sp2x);
		--errorLogoTop: var(--sp1x);
		--errorButtonBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--contentWrapPadding: var(--sp15x) 0;
		--descriptionMarginTop: var(--sp1-5x);
		--errorLogoTop: var(--sp1x);
		--errorButtonBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--contentWrapPadding: var(--sp18x) 0;
		--descriptionMarginTop: var(--sp1-5x);
		--errorLogoTop: var(--sp1x);
		--errorButtonBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--contentWrapPadding: var(--sp18x) 0;
		--descriptionMarginTop: var(--sp1-5x);
		--errorLogoTop: var(--sp1-5x);
		--errorButtonBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--contentWrapPadding: var(--sp30x) 0;
		--descriptionMarginTop: var(--sp1-5x);
		--errorLogoTop: var(--sp1-5x);
		--errorButtonBottom: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--contentWrapPadding: var(--sp30x) 0;
		--descriptionMarginTop: var(--sp1x);
		--errorLogoTop: var(--sp1-5x);
		--errorButtonBottom: var(--sp4x);

		.content-wrap {
			border: none;
		}

		.error-button {
			position: relative;
			bottom: 0;
			left: 0;
			transform: unset;
			margin-top: var(--sp4x);
		}
	}
`;

export default Custom404Style;
